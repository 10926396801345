// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bags-js": () => import("./../src/pages/bags.js" /* webpackChunkName: "component---src-pages-bags-js" */),
  "component---src-pages-caps-js": () => import("./../src/pages/caps.js" /* webpackChunkName: "component---src-pages-caps-js" */),
  "component---src-pages-connections-js": () => import("./../src/pages/connections.js" /* webpackChunkName: "component---src-pages-connections-js" */),
  "component---src-pages-designs-js": () => import("./../src/pages/designs.js" /* webpackChunkName: "component---src-pages-designs-js" */),
  "component---src-pages-fabric-suppliers-js": () => import("./../src/pages/fabric-suppliers.js" /* webpackChunkName: "component---src-pages-fabric-suppliers-js" */),
  "component---src-pages-gowns-js": () => import("./../src/pages/gowns.js" /* webpackChunkName: "component---src-pages-gowns-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-masks-js": () => import("./../src/pages/masks.js" /* webpackChunkName: "component---src-pages-masks-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-safety-information-js": () => import("./../src/pages/safety-information.js" /* webpackChunkName: "component---src-pages-safety-information-js" */),
  "component---src-pages-scrubs-js": () => import("./../src/pages/scrubs.js" /* webpackChunkName: "component---src-pages-scrubs-js" */),
  "component---src-pages-visors-js": () => import("./../src/pages/visors.js" /* webpackChunkName: "component---src-pages-visors-js" */)
}

